import React from "react"
import Img from "gatsby-image"

import { graphql, Link } from "gatsby"
import SEO from "../components/seo"

import {
  Container,
  Header,
  Section,
  Main,
  Card,
  Tag,
  EpisodeCard,
  Button,
  Footer,
} from "../components/Lib"
import {
  toJalaliDate,
  toPersianNumber,
  transformEpisodesData,
} from "../utils/helpers"

export const BlogPost = ({ post }) => (
  <Link className="navigator blog__post" to={`/blog/${post?.fields?.slug}`}>
    <figure className="blog__post__image">
      <Img fluid={post?.mainImage?.childImageSharp?.fluid} alt={post?.title} />
    </figure>
    <div className="blog__post__info">
      <div className="blog__post__details">
        {post?.category?.name} ∙ {toJalaliDate(new Date(post?.created_at))} ∙{" "}
        {toPersianNumber(post?.fields?.readingTime)} دقیقه
      </div>
      <div className="blog__post__title">{post?.title}</div>
      <p className="blog__post__description">
        {post?.description?.slice(0, 250)}...
      </p>
    </div>
  </Link>
)

export const BlogHeading = ({
  children,
  actionTitle,
  actionLink,
  actionIcon,
}) => (
  <header className="blog__heading">
    <h3 className="blog__heading__title">{children}</h3>

    {actionTitle && (
      <div className="blog__heading__action">
        <Button
          Tag="Link"
          to={actionLink}
          iconName={actionIcon}
          color="gray-outlined"
          size="small"
        >
          {actionTitle}
        </Button>
      </div>
    )}
  </header>
)

export const BlogPosts = ({ posts, skip = 0 }) => (
  <div className="blog__posts">
    {posts.slice(skip).map(({ node: post }) => (
      <BlogPost key={post?.fields.slug} post={post} />
    ))}
  </div>
)

const Blog = ({ data }) => {

	const posts = data.allStrapiPost.edges
	const latestPost = posts[0].node
	const latestEpisode = transformEpisodesData(data.episode.edges)[0]
	const categories = data.categories.edges
	console.log(posts, latestPost, latestEpisode, categories);
  return (
    <Container>
      <SEO
        title="بلاگ | جدیدترین اخبار و مقالات درباره زندگی افراد موفق مانند ایلان ماسک، جف بزوس و"
        description="اخبار، داستان ها و مقالات مرتبط به افراد موفق را در بلاگ بایوکست دنبال کنید"
        keywords={["بایوکست", "پادکست زندگینامه", "زندگینامه افراد موفق"]}
      />
      <Header />
      <Main className="blog">
        <Section className="blog__header">
          <h1 className="blog__title">بایوکست بلاگ</h1>
          <div className="inner_wrapper">
            <Card
              className="blog__latest_post card--primary"
              standalone
              imageFluid={latestPost?.mainImage?.childImageSharp?.fluid}
              imageAlt={latestPost?.title}
              imagePosition={latestPost?.imagePosition}
              tagComponent={<Tag>آخرین نوشته</Tag>}
              tag="Link"
              link={`/blog/${latestPost?.fields.slug}`}
              overlayColor="--primary"
            >
              <div className="blog__latest_post__wrapper">
                <div className="blog__latest_post__info">
                  {latestPost?.category?.name} ∙{" "}
                  {toJalaliDate(new Date(latestPost?.created_at))} ∙{" "}
                  {toPersianNumber(latestPost?.fields?.readingTime)} دقیقه
                </div>
                <div className="blog__latest_post__title">
                  {latestPost?.title}
                </div>
              </div>
            </Card>
            <EpisodeCard
              className="blog__latest_episode"
              item={latestEpisode}
              standalone
              tagComponent={<Tag color="primary">جدیدترین اپیزود</Tag>}
              tag="Link"
              link={`/episode/${latestEpisode.slug}`}
            />
          </div>
        </Section>
        <Section backgroundColor="var(--lightgray)">
          <div className="inner_wrapper">
            <BlogHeading
              actionIcon="list"
              actionTitle="مشاهده همه نوشته‌ها"
              actionLink="/blog/all"
            >
              آخرین نوشته ها
            </BlogHeading>
            <BlogPosts posts={posts} skip={1} />
          </div>
        </Section>
      </Main>
      <Footer />
    </Container>
  )
}

export const query = graphql`
  query {
    categories: allStrapiCategory {
      edges {
        node {
          name
        }
      }
    }
    episode: allStrapiEpisode(
      sort: { fields: episodeNumber, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          created_at
          title
          episodeNumber
          mainImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          squareImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    allStrapiPost(sort: { fields: created_at, order: DESC }, limit: 5) {
      edges {
        node {
          created_at
          content
          description
          imagePosition
          title
          category {
            name
          }
          mainImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fields {
            slug
            readingTime
          }
        }
      }
    }
  }
`

export default Blog
